:root {
  --color-test:   #3E497A;
}

@font-face {
  font-family: 'Inter';
  src: 
    url('./fonts/InterFont/Inter-Bold.woff2') format('woff2'),
    url('./fonts/InterFont/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: 
    url('./fonts/InterFont/Inter-ExtraBold.woff2') format('woff2'),
    url('./fonts/InterFont/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: 
    url('./fonts/InterFont/Inter-SemiBold.woff2') format('woff2'),
    url('./fonts/InterFont/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: 
    url('./fonts/InterFont/Inter-Regular.woff2') format('woff2'),
    url('./fonts/InterFont/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: 
    url('./fonts/InterFont/Inter-Medium.woff2') format('woff2'),
    url('./fonts/InterFont/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: 
    url('./fonts/InterFont/Inter-Light.woff2') format('woff2'),
    url('./fonts/InterFont/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* MAIN */
  .container__section { @apply md:w-[calc(100%-15rem)]  }

  .container__section--minimize { @apply md:w-full }

  .container__section--padding { @apply px-5 py-20 md:px-10 md:py-5 }

  .fill__section { @apply md:w-60 }

  .fill__section--minimize { @apply md:w-16 }

  .section__wrapper { @apply flex flex-col gap-5; }

  .section__header { @apply border-b border-b-gray-300 py-2 }

  .section__header--font { @apply text-gray-600 font-inter font-semibold text-sm tracking-tighter uppercase }

  .section__tab { @apply cursor-pointer active:text-blue-500 }

  /* END MAIN */

  /* FORMS */
  .field__wrapper { @apply flex flex-col md:flex-row gap-2 }

  .field__label { @apply block my-auto md:w-1/5 }

  .field__label--font { @apply font-inter font-medium text-xs text-gray-700 }

  .field__input { @apply flex flex-col gap-1 md:w-4/5 }

  .input__box { @apply bg-white border border-gray-300 block rounded-md p-3 }

  .input__box--font { @apply text-xs text-gray-600 }

  .input__box--focus { @apply focus:ring-1 focus:outline-none focus:ring-purple-800 focus:border-pink-100 }

  .field__error { @apply font-medium text-red-400 text-xs tracking-tight pl-1 }

  .form__btn { @apply bg-accent rounded-lg px-5 py-2.5 }

  .form__btn--font { @apply font-semibold text-center text-xs text-white uppercase }
  
  .form__btn--hover { @apply hover:bg-indigo-900/80 }

  .form__btn--active { @apply active:scale-105 }

  .form__btn--focus { @apply focus:ring-2 focus:outline-none focus:ring-purple-300 }

  .form__message { @apply font-inter font-semibold text-sm text-center text-accent uppercase tracking-tight }
  
  /* END FORMS */

  /* MODAL */

  .modal__btn { @apply border p-2 rounded-md text-lg focus:ring-2 focus:outline-none active:scale-105 shadow-md shadow-black/50 }

  .modal__btn--green { @apply bg-green-600 border-green-500/50 focus:ring-green-700 text-gray-50 }

  .modal__btn--blue { @apply bg-blue-700 border-blue-800/50 focus:ring-blue-800 text-gray-50 }

  .modal__btn--red { @apply bg-red-500 border-red-600/50 focus:ring-red-700 text-gray-50 }

  .modal__btn--yellow { @apply bg-yellow-500 border-yellow-600/50 focus:ring-amber-600 }

  /* END MODAL */
 
  /* NAVBAR */
     
  .nav__subheader { @apply uppercase text-xs font-bold font-inter text-gray-200 }

  .nav__link { @apply flex gap-2 py-2 px-4 cursor-pointer }

  .nav__link--font { @apply font-medium font-inter text-xs text-gray-300 }

  .nav__link--hover { @apply hover:bg-indigo-100/10  hover:rounded-lg }

  .display { @apply translate-x-full } 

  /* END NAVBAR */

  /* LOGIN */

  .login-input {
    @apply
    block 
    bg-gray-50 
    border border-gray-300 rounded-lg 
    text-xs text-gray-700
    focus:outline-none focus:border-accent focus:ring-1 focus:ring-accent
    w-full 
    pl-10 p-3
  }

  .login-label {
    @apply block mb-2 text-xs uppercase font-semibold text-accent tracking-tight
  }

  .login-btn {
    @apply
    text-sm text-center text-white font-medium 
    bg-accent hover:bg-indigo-900  active:scale-105
    focus:ring focus:outline-none focus:ring-indigo-900
    rounded-lg 
    mt-3 px-5 py-2.5
    w-full 
  }

  /* END LOGIN */

}

@layer utilities {
  .font-inter {
    font-family: 'Inter';
  }

  .wrapper {
    width: 100%;     
  }

  .label {
    font-size: 12px;
    font-weight: 700;    
    color: #5a5a5a;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: fit-content;
  }  
}